.toggle-btn-group {
  .MuiToggleButtonGroup-root {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    .filter-tab {
      @include pxRem(font-size, 14);
      @include pxRem(border-radius, 1000);
      @include pxRem(padding, 8 16);
      font-weight: 500;
      border: 0;
      text-transform: none;
      &:not(.Mui-disabled) {
        color: map-get($colors, txt-black-1);
      }
      &.Mui-selected {
        background-color: map-get($colors, bg-violet);
        &:hover {
          background-color: map-get($colors, bg-violet);
        }
      }
    }
  }
}
