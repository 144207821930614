.title-detail {
  @include pxRem(margin-top, 24);
  word-wrap: break-word;
  word-break: break-all;
  .text-detail {
    @include pxRem(margin-right, 4);
    @include pxRem(top, 4);
    position: relative;
    &.location {
      @include pxRem(margin-left, 16);
    }
    &-break {
      display: none;
    }
  }
}
@include respond-below(medium) {
  .title-detail {
    .text-detail {
      &.location {
        margin-left: 0;
      }
      &-break {
        @include pxRem(margin-top, 4);
        display: block;
      }
    }
  }
}
