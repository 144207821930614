.page-title {
	svg {
		@include pxRem(font-size, 32);
		@include pxRem(margin-right, 8);
		color: map-get($colors, txt-gray-1);
	}
	.title {
		@include pxRem(font-size, 24);
		@include pxRem(line-height, 32);
		font-weight: 700;
		margin: 0;
		color: map-get($colors, txt-black-1);
	}
}
