button.btn,
label.btn {
  @include pxRem(padding, 9 16);
  @include pxRem(border-radius, 8);
  @include pxRem(font-size, 14);
  @include pxRem(height, 40);
  color: map-get($colors, blue);
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  border: 0;
  text-transform: unset;
  min-width: unset;
  .icon-arrow {
    @include pxRem(font-size, 18);
    @include pxRem(margin-right, 8);
  }
  &.btn-secondary {
    @include pxRem(border-radius, 100);
    background-color: map-get($colors, bg-blue-1);
    color: map-get($colors, txt-black-2);
    .icon-add {
      @include pxRem(margin-right, 8);
      @include pxRem(font-size, 20);
      color: map-get($colors, txt-gray-1);
    }
  }

  &.btn-primary {
    @include pxRem(border-radius, 100);
    background-color: map-get($colors, txt-blue-1);
    color: map-get($colors, white);
    &:disabled {
      background-color: map-get($colors, gray-2);
      color: map-get($colors, txt-gray-5);
    }
  }
  
  &.btn-no-border {
    @include pxRem(border-radius, 100);
    color: map-get($colors, txt-blue-1);
    .icon-border-color {
      @include pxRem(font-size, 18);
    }
    .icon-visibility {
      @include pxRem(font-size, 16);
    }
    &:disabled {
      color: map-get($colors, txt-gray-3);
    }
  }
  &.btn-white {
    @include pxRem(border-radius, 100);
    border: 1px solid map-get($colors, bg-gray-3);
  }
  &.btn-action-icon {
    color: map-get($colors, txt-black-1);
    &:disabled {
      opacity: 0.8;
    }
  }
}

.btn-switch.MuiSwitch-root {
  @include pxRem(width, 52);
  @include pxRem(height, 32);
  @include pxRem(border-radius, 100);
  padding: 0;
  border: 2px solid map-get($colors, bg-gray-3);
  &.checked {
    border: 0;
    background-color: map-get($colors, blue);
    .MuiButtonBase-root {
      @include pxRem(padding, 6);
    }
  }
  .MuiButtonBase-root {
    @include pxRem(padding, 4);
    &.Mui-checked {
      .MuiSwitch-thumb {
        background-color: map-get($colors, bg-gray-4);
      }
      & + .MuiSwitch-track {
        background-color: map-get($colors, blue);
        opacity: 1;
      }
    }
    .MuiSwitch-thumb {
      background-color: map-get($colors, bg-gray-3);
    }
    &.Mui-checked + .MuiSwitch-track {
      background-color: unset;
    }
  }
  .MuiSwitch-track {
    background-color: map-get($colors, white);
  }
}

@include respond-below(medium) {
  button.btn,
  label.btn {
    &.btn-no-border {
      &:focus,
      &:focus-visible,
      &:active {
        background-color: unset;
      }
    }
  }
}
