.page-header {
  @include pxRem(padding, 10 24);
  background: map-get($colors, white);
  border-bottom: 1px solid map-get($colors, border-gray-1);
  position: fixed;
  width: 100%;
  z-index: map-get($zindexs, fixed);
}
#menu-appbar {
  .MuiMenu-list {
    li:first-child:hover {
      background-color: map-get($colors, bg-gray-5) !important;
    }
  }
}
