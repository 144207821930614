* {
  box-sizing: border-box;
}
body {
  font-family: $font-body;
  font-weight: map-get($font-weight, normal);
  font-size: $font-size-base;
  letter-spacing: -0.025em;
}
p {
  line-height: 1.45;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
a {
  color: map-get($colors, txt-black);
  text-decoration: none;
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: map-get($font-weight, medium);
}
h1 {
  font-size: map-get($h-font-size, h1);
}
h2 {
  font-size: map-get($h-font-size, h2);
}
h3 {
  font-size: map-get($h-font-size, h3);
}
h4 {
  font-size: map-get($h-font-size, h4);
}
h5 {
  font-size: map-get($h-font-size, h5);
}
h6 {
  font-size: map-get($h-font-size, h6);
}
