.data-alert {
  @include pxRem(width, 344);
  @include pxRem(top, 100);
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: map-get($zindexs, alert);
  background-color: map-get($colors, bg-black) !important;
  opacity: 0;
  animation: fade-in 3s;
  .MuiAlert-message {
    @include pxRem(font-size, 14);
    font-weight: 400;
    color: map-get($colors, txt-white);
  }
  .MuiAlert-action {
    color: map-get($colors, txt-white);
    padding: 0;
    svg {
      @include pxRem(font-size, 25);
    }
  }
}

@include respond-below(medium) {
  .data-alert {
    width: calc(100vw - #{convertPxToRem(50)});
  }
}

@keyframes fade-in {
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
