.wrapper-upload-file {
  @include pxRem(padding, 24 16);
  border: 1px dashed map-get($colors, bg-gray-3);
  .upload-button {
    @include pxRem(border-radius, 100);
    @include pxRem(padding, 9 16);
    @include pxRem(margin, auto);
    @include pxRem(height, 40);
    background-color: map-get($colors, bg-blue-1);
    color: map-get($colors, txt-black);
  }
  .upload-err-message {
    color: map-get($colors, txt-error);
    text-align: center;
  }
  .preview-container:first-child {
    @include pxRem(margin-right, 8);
  }
  .preview-file {
    @include pxRem(height, 40);
    @include pxRem(padding, 8);
    @include pxRem(border-radius, 4);
    border: 1px solid map-get($colors, border-white);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img,
    video,
    .invalid-src {
      @include pxRem(width, 40);
      @include pxRem(height, 40);
      object-fit: contain;
      position: absolute;
      top: -1px;
      left: -1px;
    }
    p {
      @include pxRem(padding-left, 40);
      color: map-get($colors, txt-gray-3);
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .btn-play {
      @include pxRem(left, 19);
      @include pxRem(width, 20);
      @include pxRem(height, 20);
      position: absolute;
      top: 1px;
      transform: translate(-50%, -50%);
    }
  }
  .preview-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    max-width: calc(50% - #{convertPxToRem(4px)});
    -webkit-tap-highlight-color: transparent;
  }
  .wrapper-upload {
    flex-wrap: wrap;
  }
}

.menu-preview-file {
  ul {
    padding: 0;
    .menu-item {
      @include pxRem(padding, 16 12);
      label {
        margin: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid map-get($colors, border-gray-2);
      }
    }
  }
}

.MuiPopover-paper {
  .Mui-focusVisible {
    background-color: transparent !important;
  }
}
@include respond-below(medium) {
  .wrapper-upload {
    flex-direction: column;
    align-items: flex-start !important;
    .preview-container {
      max-width: 100%;
      &:first-child {
        @include pxRem(margin-bottom, 8);
        margin-right: 0;
      }
    }
  }
}
