.machine-detail-page {
  .machine-detail-right {
    width: calc(100% - #{convertPxToRem(238)});
    .wrapper-machines-and-table {
      @include pxRem(padding-left, 24);
      @include pxRem(padding-right, 24);
      width: 100%;
      flex-flow: column wrap;
    }
  }
}
.machine-info {
  @include pxRem(padding, 40 16);
  @include pxRem(width, 238);
  min-height: calc(100vh - #{convertPxToRem(65)});
  background-color: map-get($colors, white);
  .machine-title {
    .icon-back {
      @include pxRem(font-size, 12);
      @include pxRem(margin, 8);
    }
    .title {
      @include pxRem(font-size, 14);
      @include pxRem(margin-left, 8);
      font-family: $font-hiragino-kaku-gothic-pro;
      font-weight: 300;
    }
  }
  .wrapper-dropdown-info {
    pointer-events: none;
    @include pxRem(margin-top, 16);
    .wrapper-image {
      .info-image {
        @include pxRem(border-radius, 8);
        @include pxRem(width, 206);
        @include pxRem(height, 206);
        object-fit: contain;
      }
      .info-status {
        @include pxRem(margin-top, 14);
        .text {
          @include pxRem(font-size, 24);
          max-width: calc(100% - #{convertPxToRem(34)});
          font-weight: 700;
          color: map-get($colors, txt-black-1);
        }
      }
    }
    .icon-arrow-down {
      display: none;
    }
  }
  .info-list {
    .info-item {
      @include pxRem(padding-top, 16);
      &:first-child {
        padding-top: 0;
      }
      &-label {
        @include pxRem(font-size, 12);
        @include pxRem(line-height, 16);
        font-weight: 400;
        color: map-get($colors, txt-gray-1);
      }
      &-content {
        @include pxRem(font-size, 16);
        @include pxRem(line-height, 24);
        @include pxRem(margin, 3 0 0);
        font-weight: 400;
        color: map-get($colors, gray);
      }
    }
  }
}
.machine-open {
  @include pxRem(margin-top, 40);
  @include pxRem(border-radius, 0 8 8 0);
  border-left: 4px solid map-get($colors, orange);
  .machine-open-list {
    @include pxRem(margin-top, 12);
    .machine-open-item {
      @include pxRem(padding, 8 24);
      border-bottom: 1px solid map-get($colors, border-gray-1);
      position: relative;
      &.has-icon-badge::before {
        @include pxRem(width, 6);
        @include pxRem(height, 6);
        @include pxRem(left, 10);
        content: "";
        display: block;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        background-color: map-get($colors, blue);
      }
      &:hover {
        background-color: map-get($colors, bg-gray-4);
        cursor: pointer;
      }
      &:last-child {
        border-bottom: 0;
      }
      .item-image {
        @include pxRem(width, 20);
        @include pxRem(height, 20);
        border: 1px solid map-get($colors, border-white);
      }
    }
    .item-txt {
      @include pxRem(line-height, 24);
    }
    .item-des,
    .item-name {
      @include pxRem(line-height, 20);
    }
  }
  .item-txt-right {
    @include pxRem(line-height, 16);
  }
}

.machine-tabs {
  width: 100%;
  table td {
    @include pxRem(padding, 2 12);
    color: map-get($colors, black);
  }
  .inspection-form-tab {
    .icon-edit {
      @include pxRem(width, 19);
      @include pxRem(height, 19);
      @include pxRem(margin-right, 8);
    }
    .icon-pdf {
      @include pxRem(margin-right, 8);
    }
    .select-inspection {
      @include pxRem(width, 342);
      background-color: map-get($colors, white);
    }
    .table-inspection-form {
      @include pxRem(margin-bottom, 40);
      th {
        @include pxRem(padding, 7 12);
      }
      td,
      th {
        border-right: 1px solid map-get($colors, border-gray-1);
      }
      table {
        border: 1px solid map-get($colors, border-gray-1);
      }
      table,
      td,
      th {
        @include pxRem(line-height, 24);
        @include pxRem(height, 40);
      }
      thead {
        position: sticky;
        top: 0;
        z-index: 10;
      }
      &.iOS-less-than-15 {
        thead {
          position: initial;
          tr {
            th {
              &:first-child {
                z-index: 11;
              }
              position: sticky;
              top: 0;
              left: 0;
              z-index: 10;
            }
            &:nth-child(2) {
              th {
                @include pxRem(top, 40);
              }
            }
          }
        }
      }
    }
  }
  .wrap-filter-date {
    .date-range {
      .wrap-end-date {
        .symbol {
          @include pxRem(margin-left, 16);
          @include pxRem(margin-right, 16);
        }
      }
    }
    .wrap-right {
      .wrap-total-refueling,
      .wrap-service-meter {
        .content {
          .value-pc {
            @include pxRem(max-width, 320);
          }
        }
        .value-tl {
          display: none;
        }
      }
    }
  }
}

@include respond-below(largest) {
  .machine-detail-page {
    .wrapper-machine-detail {
      .machine-tabs {
        .wrap-filter-date {
          flex-direction: column;
          .date-range {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .wrap-end-date {
              @include pxRem(margin-top, 4 !important);
              .symbol {
                @include pxRem(margin-right, 8);
                margin-left: 0;
              }
            }
          }
          .wrap-right {
            @include pxRem(margin-top, 24);
            flex-direction: column;
            width: 100%;
            margin-left: 0 !important;
            padding-right: 0 !important;
            .wrap-total-refueling,
            .wrap-service-meter {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .content {
                display: flex;
                flex-direction: column;
                text-align: start !important;
                .value-pc {
                  display: none;
                }
              }
              .value-tl {
                display: block;
                text-align: right;
                max-width: calc(100vw - #{convertPxToRem(438)});
              }
            }
            .wrap-service-meter {
              .content {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-below(wide) {
  .machine-detail-page {
    .wrapper-machine-detail {
      .machine-tabs {
        .wrap-action-inspection {
          flex-wrap: wrap;
          flex-direction: column;
          align-items: unset;
          .select-inspection {
            @include pxRem(margin-top, 16);
            margin-left: 0 !important;
            width: 100%;
          }
          .wrap-date {
            justify-content: space-between !important;
            flex-wrap: wrap;
            width: 100%;
          }
          .wrap-btns {
            @include pxRem(margin-top, 16);
          }
        }
      }
    }
  }
}

@include respond-below(medium) {
  .machine-info {
    @include pxRem(padding, 40 24 0);
    background-color: unset;
    width: 100%;
    height: auto;
    margin-left: 0;
    min-height: auto;
    .wrapper-dropdown-info {
      pointer-events: all;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wrapper-image {
        display: flex;
        .info-image {
          @include pxRem(width, 70);
          @include pxRem(height, 70);
        }
        .info-status {
          @include pxRem(margin-left, 16);
          margin-top: 0;
          .text {
            max-width: unset;
            flex: 1;
          }
        }
      }
      .icon-arrow-down {
        @include pxRem(margin, 14);
        @include pxRem(font-size, 24);
        display: block;
        color: map-get($colors, txt-gray-1);
      }
    }
    .machine-open {
      @include pxRem(margin-top, 24);
    }
  }
  .machine-open {
    .machine-open-list {
      .machine-open-item {
        @include pxRem(padding, 8 16);
        &.has-icon-badge::before {
          @include pxRem(left, 5);
        }
      }
    }
  }
  .machine-detail-page {
    min-height: calc(100vh - #{convertPxToRem(130)});
    .wrapper-machine-detail {
      flex-wrap: wrap;
      .machine-tabs {
        @include pxRem(margin-left, -24);
        @include pxRem(margin-right, -24);
        padding: 0;
        .data-tabs {
          width: 100vw;
        }
        .wrap-filter-date {
          .wrap-right {
            @include pxRem(padding-right, 24 !important);
            .wrap-total-refueling,
            .wrap-service-meter {
              .value-tl {
                max-width: calc(100vw - #{convertPxToRem(200)});
              }
            }
          }
        }
      }
    }
    .machine-detail-right {
      width: 100%;
    }
  }
}

.label-inquire,
.label-report {
  @include pxRem(font-size, 12);
  @include pxRem(line-height, 16);
  @include pxRem(padding, 4 8);
  @include pxRem(border-radius, 8);
}
