.swiper {
  .wrapper-file {
    @include pxRem(height, 240);
    text-align: center;
    &.inspection-form {
      @include pxRem(height, 210);
      overflow: hidden;
    }
    .swiper-image {
      @include pxRem(height, 210);
      object-fit: contain;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    @include pxRem(width, 40);
    @include pxRem(height, 40);
    background: map-get($colors, bg-white);
    border-radius: 50%;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    @include pxRem(font-size, 12);
    color: map-get($colors, blue);
  }
  .swiper-pagination-bullet-active {
    background-color: map-get($colors, blue);
  }
}
