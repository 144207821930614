.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none;
}
.d-none-imp {
  display: none !important;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-block {
  display: block !important;
}

@include respond-below(medium) {
  .d-block-sp {
    display: block !important;
  }
  .d-none-sp {
    display: none !important;
  }
}
