.bg-white {
  background-color: map-get($colors, white) !important;
}
.bg-red {
  background-color: map-get($colors, red) !important;
}
.bg-gray {
  background-color: map-get($colors, bg-gray) !important;
}
.bg-purple {
  background-color: map-get($colors, bg-purple-1) !important;
}
