.cards {
  @include pxRem(border-radius, 4);
  background-color: map-get($colors, bg-gray);
  .card-item {
    -webkit-tap-highlight-color: transparent;
    &-title {
      @include pxRem(padding, 16);
      &.have-switch {
        @include pxRem(padding, 12 16);
      }
    }
    &.card-item-draggable {
      .card-item-title {
        padding: 0;
      }
      .drag-icon,
      .arrow-icon {
        color: map-get($colors, txt-gray-4);
        cursor: pointer;
      }
      .card-item-content {
        @include pxRem(padding, 0 24 16);
        display: none;
      }
      .wrapper-drap-icon {
        @include pxRem(padding, 16);
      }
      .wrapper-title-card {
        @include pxRem(padding, 16 16 16 0);
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid map-get($colors, border-gray-2);
    }
    .txt-card {
      @include pxRem(font-size, 16);
      @include pxRem(line-height, 24);
      color: map-get($colors, gray);
      font-weight: 400;
    }
  }
}

@include respond-below(medium) {
  .cards {
    .card-item {
      &-title {
        white-space: nowrap;
      }
    }
  }
}
