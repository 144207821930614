
.page-not-found {
  .wrapper-content {
    @include pxRem(padding, 80 24 24);
    .content {
      @include pxRem(padding, 64 24);
      @include pxRem(border-radius, 8);
    }
  }
}

@include respond-below(medium) {
  .page-not-found {
    .wrapper-content {
      @include pxRem(padding-top, 40);
    }
  }
}
