.input-field.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  margin: 8px 0;
  outline: none;
  input[type="text"]:focus,
  textarea[type="text"]:focus {
    box-shadow: none;
  }
  input[type="text"],
  textarea {
    border: 0;
    padding: 0;
    background: unset;
  }
  .MuiInputBase-root {
    @include pxRem(padding, 8 16);
  }
  &.input-text-area {
    .MuiInputBase-root {
      @include pxRem(min-height, 56);
    }
  }
}
.wrapper-text-field {
  position: relative;
  .icon-input-err {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  textarea {
    @include pxRem(border-radius, 4);
    @include pxRem(font-size, 16);
    resize: none;
    box-shadow: none;
    border: 1px solid map-get($colors, bg-gray-3);
    color: map-get($colors, txt-black-1);
    &::placeholder {
      color: map-get($colors, txt-gray-1);
    }
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border: 2px solid map-get($colors, txt-error) !important;
  }
}
.checkbox {
  .MuiButtonBase-root.Mui-checked {
    color: map-get($colors, blue);
  }
}

.select-field {
  .MuiSelect-select,
  .MuiMenuItem-root {
    color: map-get($colors, txt-black-2);
  }
  .MuiSelect-icon {
    color: map-get($colors, txt-gray-1);
  }
}
.MuiPaper-root .MuiList-root .MuiMenuItem-root span {
  color: map-get($colors, txt-black-2);
}

.checkbox-field.MuiFormControlLabel-root {
  margin: 0;
  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: map-get($colors, blue);
  }
  .MuiTypography-root {
    @include pxRem(font-size, 14);
    color: map-get($colors, txt-gray-3);
  }
}

.input-datePicker {
  position: relative;
  .MuiFormControl-root {
    @include pxRem(width, 240);
    label.Mui-error {
      color: map-get($colors, txt-red);
    }
    label.MuiFormLabel-root {
      @include pxRem(font-size, 12);
      transform: translate(#{convertPxToRem(14)}, #{convertPxToRem(-9)});
    }
    .MuiInputBase-formControl {
      @include pxRem(padding-right, 16);
      background-color: map-get($colors, white);
      .MuiButtonBase-root {
        color: map-get($colors, txt-gray-1);
      }
    }
    input[type="text"],
    input[type="text"]:hover,
    input[type="text"]:focus {
      @include pxRem(padding-left, 16);
      border: 0;
      box-shadow: unset;
      background-color: inherit;
    }
  }
}

@include respond-below(largest) {
  .input-datePicker {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
    }
    .icon-calendar {
      position: absolute;
      display: block !important;
      color: map-get($colors, txt-gray-1);
      background-color: map-get($colors, white);
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
}
