.loading-data {
  background-color: map-get($colors, white);
  position: fixed;
  z-index: map-get($zindexs, loading);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.8;

  .icon-loading {
    position: absolute;
    top: calc(50% - #{convertPxToRem(25)});
    left: calc(50% - #{convertPxToRem(25)});
    transform: translate(-50%, -50%);
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0%    { transform: rotateZ(0) }
  12.5% { transform: rotateZ(45deg) }
  25%   { transform: rotateZ(90deg) }
  37.5% { transform: rotateZ(135deg) }
  50%   { transform: rotateZ(180deg) }
  62.5% { transform: rotateZ(225deg) }
  75%   { transform: rotateZ(270deg) }
  87.5% { transform: rotateZ(315deg) }
  100%  { transform: rotateZ(360deg) }
}
