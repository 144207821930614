.MuiDialog-container {
  .MuiDialog-paper {
    @include pxRem(border-radius, 28);
    @include pxRem(width, 560);
    .MuiDialogTitle-root {
      @include pxRem(font-size, 24);
      @include pxRem(padding, 24 0 0 24);
      font-weight: 700;
      flex: unset;
      -webkit-flex: unset;
    }
    .MuiIconButton-root {
      position: unset;
      &:hover {
        background: none;
      }
    }
    .MuiDialogContent-dividers {
      @include pxRem(padding, 0 24);
      @include pxRem(margin-bottom, 24);
      border-top: 0;
      border-bottom: 0;
      .img-dialog {
        @include pxRem(width, 512);
        aspect-ratio: 1;
        object-fit: contain;
      }
      .btn-close {
        @include pxRem(margin-top, 24);
        float: right;
      }
    }
  }
  .MuiPaper-elevation {
    #customized-dialog-title {
      padding: 0;
      .icon-back {
        @include pxRem(font-size, 20);
        @include pxRem(margin, 0 8 0 6);
        color: map-get($colors, txt-gray-1);
      }
    }
  }
}

.dialog-location-report {
  .map-iframe {
    @include pxRem(height, 288);
    width: 100%;
    border: 0;
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      @include pxRem(margin, 24);
      .MuiDialogContent-dividers {
        @include pxRem(padding, 0 24);
        .label-dialog {
          @include pxRem(margin-top, 16);
          @include pxRem(line-height, 20);
          display: block;
        }
      }
    }
  }
}

.dialog-inspection-form {
  .MuiDialog-container .MuiDialog-paper {
    @include pxRem(width, 976);
    max-width: unset;
  }
}
.dialog-report {
  &.full-height {
    .MuiPaper-elevation {
      max-height: 100%;
    }
  }
  .sub-title-dialog {
    @include pxRem(font-size, 12);
    font-weight: 500;
    color: map-get($colors, txt-gray-1);
  }
  .status-resolved {
    display: inline-block;
    color: map-get($colors, txt-black-1);
    &::before {
      @include pxRem(width, 8);
      @include pxRem(height, 8);
      @include pxRem(margin-right, 4);
      content: "";
      background-color: map-get($colors, bg-gray-1);
      display: inline-block;
      border-radius: 50%;
    }
    &.active {
      color: map-get($colors, txt-green);
      &::before {
        background-color: map-get($colors, txt-green);
      }
    }
  }
  .MuiDialogContent-root {
    padding: 0 !important;
    margin-bottom: 0;
  }
  .content {
    &.show-update {
      @include pxRem(max-height, 200);
    }
    &.un-show-update {
      max-height: 100%;
    }
    background-color: map-get($colors, bg-gray);
    @include pxRem(padding, 24);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .report-card {
      @include pxRem(margin-bottom, 16);
      &:last-child {
        @include pxRem(margin-bottom, 0);
      }
      .content-report {
        @include pxRem(border-radius, 12);
        border: 1px solid map-get($colors, border-gray-1);
        background-color: map-get($colors, white);
        .header {
          @include pxRem(padding, 12 16);
          .report-detail {
            @include pxRem(padding-left, 16);
          }
          .report-name {
            @include pxRem(font-size, 16);
            margin: 0;
            font-weight: 700;
          }
          .report-time {
            @include pxRem(font-size, 14);
            @include pxRem(padding-top, 4);
            margin: 0;
            font-weight: 400;
          }
        }
        .wrapper-file {
          @include pxRem(height, 288);
          overflow: hidden;
          .img-report {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .text-content {
          @include pxRem(font-size, 16);
          @include pxRem(padding, 16);
          font-weight: 400;
          margin: 0;
        }
      }
      .status {
        .content-status {
          @include pxRem(font-size, 12);
          @include pxRem(margin-top, 16);
          @include pxRem(margin-left, 12);
          @include pxRem(padding, 20 0 24 16);
          border-left: 1px solid map-get($colors, border-gray-1);
          font-weight: 400;
        }
      }
    }
  }
  .update-status {
    @include pxRem(padding, 24 24 0 24);
    .MuiFormControlLabel-root {
      margin-left: 0;
      .MuiSwitch-sizeMedium {
        margin: 0 0 0 16px;
      }
    }
    .MuiFormControlLabel-label {
      color: map-get($colors, txt-gray-1);
      @include pxRem(font-size, 14);
    }
    .repair {
      @include pxRem(margin, 24 0);
      .MuiCheckbox-root {
        @include pxRem(padding, 0 16 0 0);
        // border: 1px solid map-get($colors, bg-gray-3);
      }
      .label {
        @include pxRem(margin, 0 16 0 0);
        color: map-get($colors, txt-gray-1);
      }
    }
  }
}
.swiper {
  .swiper-button-next,
  .swiper-button-prev {
    @include pxRem(width, 40);
    @include pxRem(height, 40);
    background: #f6f6f6b2;
    border-radius: 50%;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    @include pxRem(font-size, 12);
    color: map-get($colors, blue);
  }
  .swiper-pagination-bullet-active {
    background-color: map-get($colors, blue);
  }
  .swiper-pagination {
    @include pxRem(margin, 10 0);
    position: static;
  }
}

.dialog-preview-inspection {
  .warning {
    @include pxRem(margin-bottom, 24);
  }
  .preview-phone {
    @include pxRem(width, 348);
    @include pxRem(height, 749);
    @include pxRem(border-radius, 28);
    margin: 0 auto;
    border: 1px solid map-get($colors, bg-gray-3);
    .header-phone {
      @include pxRem(border-radius, 28 28 0 0);
      @include pxRem(padding, 24 18);
      .time {
        @include pxRem(font-size, 17);
        @include pxRem(padding-left, 20);
        font-weight: 700;
        color: map-get($colors, black);
      }
    }
    .wrapper-title {
      @include pxRem(padding, 18 16);
      .title-name {
        @include pxRem(max-width, 275);
      }
    }
    .wrapper-content-preview {
      @include pxRem(height, 560);
      overflow-y: auto;
      .inspection-list {
        .inspection-item {
          @include pxRem(padding, 12 16);
          border-bottom: 1px solid map-get($colors, border-gray-2);
        }
      }
      .inspection-list-numberic {
        @include pxRem(padding, 16 24 0);
        .wrapper-input-field {
          @include pxRem(margin-top, 16);
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.dialog-machine-report {
  .swiper {
    .wrapper-file {
      @include pxRem(height, 288);
      .swiper-image {
        height: 100%;
      }
    }
  }
}

@include respond-below(medium) {
  .MuiDialog-container .MuiDialog-paper {
    @include pxRem(margin, 40 16);
  }
  .dialog-location-report {
    .map-iframe {
      @include pxRem(height, 174);
    }
  }
  .dialog-inspection-form {
    button.btn.btn-no-border {
      @include pxRem(padding, 9 12);
      .sp-d-none {
        display: none;
      }
      .icon-border-color {
        @include pxRem(font-size, 24);
      }
      .icon-visibility {
        @include pxRem(font-size, 24);
      }
    }
  }
  .header-actions {
    button.btn.btn-no-border {
      @include pxRem(padding, 8);
      .sp-d-none {
        display: none;
      }
      .icon-border-color,
      .icon-visibility {
        @include pxRem(font-size, 24);
      }
    }
  }
  .dialog-machine-report {
    .content .report-card .content-report {
      .swiper {
        .wrapper-file {
          @include pxRem(height, 175);
        }
      }
    }
  }
}
@include respond-below(small) {
  .MuiDialogContent-dividers {
    .content {
      @include pxRem(max-height, 360);
    }
    .group-btn-footer {
      .btn {
        @include pxRem(font-size, 12);
      }
    }
  }
  .dialog-preview-inspection {
    .preview-phone {
      width: 100%;
      .wrapper-title {
        .title-name {
          max-width: calc(100vw - #{convertPxToRem(155)});
        }
      }
    }
  }
}
