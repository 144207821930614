@include buildSpace("m"); // Margin
@include buildSpace("p"); // Padding

@include respond-below(medium) {
  .sp-p24 {
    @include pxRem(padding-left, 24);
    @include pxRem(padding-right, 24);
  }
}
.pt-40 {
  @include pxRem(padding-top, 40);
}
.ml-12 {
  @include pxRem(margin-left, 12);
}
.mt-24 {
  @include pxRem(margin-top, 24 !important);
}
.mt-16 {
  @include pxRem(margin-top, 16 !important);
}
.ml-16 {
  @include pxRem(margin-left, 16 !important);
}
.mr-16 {
  @include pxRem(margin-right, 16 !important);
}
.pl-24 {
  @include pxRem(padding-left, 24 !important);
}
.pr-24 {
  @include pxRem(padding-right, 24 !important);
}
.p-24 {
  @include pxRem(padding, 24);
}
.mb-24 {
  @include pxRem(margin-bottom, 24 !important);
}
.mt-4 {
  @include pxRem(margin-top, 4);
}
.mt-64 {
  @include pxRem(margin-top, 64 !important);
}
.mt-40 {
  @include pxRem(margin-top, 40 !important);
}
.ml-40 {
  @include pxRem(margin-left, 40 !important);
}
.mt-8 {
  @include pxRem(margin-top, 8 !important);
}
.ml-8 {
  @include pxRem(margin-left, 8 !important);
}
.pr-4 {
  @include pxRem(padding-right, 4 !important);
}
.mb-8 {
  @include pxRem(margin-bottom, 8 !important);
}
.mb-16 {
  @include pxRem(margin-bottom, 16 !important);
}
.pr-8 {
  @include pxRem(padding-right, 8 !important);
}
.pt-24 {
  @include pxRem(padding-top, 24);
}
.pb-24 {
  @include pxRem(padding-bottom, 24 !important);
}
.pt-16 {
  @include pxRem(padding-top, 16 !important);
}
.pt-44 {
  @include pxRem(padding-top, 44 !important);
}
.pd-4 {
  @include pxRem(padding, 4 !important);
}
