.data-table {
  .MuiTableSortLabel-root {
    color: map-get($colors, gray) !important;
    .MuiTableSortLabel-icon {
      margin: 0 !important;
      opacity: 1 !important;
    }
  }
}

.empty-table {
  @include pxRem(padding, 64 0);
  svg {
    @include pxRem(width, 64);
    @include pxRem(height, 64);
  }
  .text-content {
    @include pxRem(font-size, 16);
    @include pxRem(margin, 14 0 0);
    font-weight: 400;
    color: map-get($colors, txt-black-1);
  }
}

@include respond-below(medium) {
  .data-table-container {
    @include pxRem(margin-left, -24);
    @include pxRem(margin-right, -24);
  }
  .empty-table {
    @include pxRem(margin, 0 24);
  }
}
