.group-detail-page {
  @include pxRem(padding, 40 24 0);
  .MuiTabs-root {
    .MuiButtonBase-root {
      @include pxRem(font-size, 14);
      font-weight: 700;
      color: map-get($colors, txt-black-1);
    }
    .Mui-disabled {
      opacity: 0.3;
    }
  }
  .machine-tab,
  .inspection-tab {
    .machine-table {
      @include pxRem(margin-top, 28);
      .serial-number {
        @include pxRem(min-width, 120);
        color: map-get($colors, txt-black-1);
      }
      .link-style {
        color: map-get($colors, txt-blue-1);
        text-decoration: underline map-get($colors, txt-blue-1);
      }
      .report-open {
        @include pxRem(font-size, 11);
        @include pxRem(margin-left, 4);
        @include pxRem(width, 16);
        @include pxRem(height, 16);
        background-color: map-get($colors, yellow);
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
      }
      .MuiTableCell-root {
        @include pxRem(padding, 4 12);
      }
    }
  }
  .inspection-tab {
    .btn-add-inspect {
      text-transform: none;
    }
    .MuiButton-disableElevation {
      @include pxRem(padding, 11 24 11 16);
      @include pxRem(border-radius, 100);
      background-color: map-get($colors, txt-blue-1);
    }
    .inspection-table-container {
      @include pxRem(margin-top, 24);
      .data-table td {
        padding: 4px 12px;
      }
      .status {
        @include pxRem(font-size, 12);
        &::before {
          @include pxRem(width, 8);
          @include pxRem(height, 8);
          @include pxRem(margin-right, 4);
          content: "";
          background-color: map-get($colors, bg-gray-1);
          display: inline-block;
          border-radius: 50%;
        }
        &.active {
          color: map-get($colors, txt-green);
          &::before {
            background-color: map-get($colors, txt-green);
          }
        }
      }
    }
    .bnt-action-form {
      @include pxRem(padding, 0);
      min-width: 0;
      &:hover {
        background-color: inherit;
      }
    }
  }
}
.has-text-link {
  color: map-get($colors, txt-blue-1);
  text-decoration: underline;
}
.form-confirm-delete {
  .button-form-delete {
    @include pxRem(font-size, 14);
    @include pxRem(margin-top, 24);
    .btn-cancel {
      @include pxRem(border-radius, 100);
      @include pxRem(padding, 10 24);
      color: map-get($colors, blue);
      text-transform: unset;
    }
    .btn-delete {
      @include pxRem(padding, 10 24);
      @include pxRem(border-radius, 100);
      @include pxRem(margin-left, 16);
      background-color: map-get($colors, red);
      color: map-get($colors, white);
      text-transform: unset;
    }
  }
}
.form-edit-name {
  @include pxRem(margin-top, 6);
  .MuiFormControl-root {
    width: 100%;
    input[type="text"] {
      border: none;
    }
    .MuiInputBase-input:focus {
      box-shadow: none;
    }
  }
  .btn-group-footer {
    @include pxRem(margin-top, 24);
    .btn-save {
      @include pxRem(padding, 10 24);
      @include pxRem(border-radius, 100);
      float: right;
      &,
      &:hover {
        color: map-get($colors, white);
      }
      &:disabled {
        background-color: map-get($colors, gray-2);
        color: map-get($colors, gray);
        opacity: 0.38;
      }
    }
  }
}

.warning {
  @include pxRem(padding, 12);
  @include pxRem(margin-top, 24);
  @include pxRem(border-radius, 4);
  background-color: map-get($colors, yellow-1);
  border: 1px solid map-get($colors, orange);
}

@include respond-below(medium) {
  .group-detail-page {
    @include pxRem(padding, 40 0 0);
    .machine-tab {
      .MuiToggleButtonGroup-root {
        @include pxRem(padding-left, 24);
      }
    }
    .inspection-tab {
      .group-button {
        @include pxRem(margin, 24);
        display: block;
        .btn-add-inspect {
          @include pxRem(margin-top, 16);
        }
      }
    }
  }
}
