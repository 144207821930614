.data-pagination {
  @include pxRem(padding-bottom, 40);
  .pagination-total-text {
    @include pxRem(font-size, 14);
    @include pxRem(line-height, 20);
    color: map-get($colors, txt-black-1);
    font-weight: 400;
  }
  .pagination {
    button {
      @include pxRem(width, 40);
      @include pxRem(height, 40);
      @include pxRem(border-radius, 8);
      @include pxRem(font-size, 14);
      @include pxRem(line-height, 20);
      @include pxRem(margin, 0 2 0 2);
      border: 1px solid map-get($colors, border-white);
      background-color: map-get($colors, white);
      color: map-get($colors, txt-black-1);
      &.Mui-selected {
        background-color: map-get($colors, blue);
        color: map-get($colors, white);
        border: none;
      }
    }
    .MuiPaginationItem-ellipsis {
      @include pxRem(width, 40);
      @include pxRem(height, 40);
      @include pxRem(border-radius, 8);
      @extend .flex-centered;
      background-color: map-get($colors, white);
      color: map-get($colors, txt-black-1);
    }
  }
}

@include respond-below(medium) {
  .data-pagination {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    align-items: flex-start !important;
    .pagination-total-text {
      @include pxRem(margin-top, 16);
    }
    .pagination {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .MuiPagination-ul {
        flex-wrap: nowrap;
        overflow: scroll;
      }
      // In mobile, if we select page 3, we hide page 5 as confirmed.
      &.has-page3 {
        .MuiPaginationItem-page[aria-label="Go to page 5"] {
          display: none;
        }
      }
    }
  }
}
