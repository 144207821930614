.license {
  @include pxRem(padding-bottom, 40);

  > table {
    width: 100%;
    margin: auto;
  }
  table {
    th,
    td {
      border: 1px solid map-get($colors, black);
      border-collapse: collapse;
      padding: convertPxToRem(15);
    }
    th {
      font-weight: map-get($font-weight, bold);
      background-color: map-get($colors, bg-gray-1);
      text-align: center;
      border-top: none;
      &:first-child {
        @include pxRem(border-top-left-radius, 7);
        border-left: none;
      }
      &:last-child {
        @include pxRem(border-top-right-radius, 7);
        border-right: none;
      }
    }
    td {
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    tr:last-child {
      td {
        border-bottom: none;
        &:first-child {
          @include pxRem(border-bottom-left-radius, 8);
        }
        &:last-child {
          @include pxRem(border-bottom-right-radius, 8);
        }
      }
    }
  }

  .table-wrapper {
    @include pxRem(border-radius, 8);
    overflow-x: auto;
    border: 1px solid map-get($colors, border-gray-1);
  }
}

@include respond-below(medium) {
  .license {
    .table-wrapper {
      border-radius: 0;
    }
  }
}
