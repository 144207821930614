.fs-16 {
  @include pxRem(font-size, 16 !important);
}
.fs-14 {
  @include pxRem(font-size, 14 !important);
}
.fs-12 {
  @include pxRem(font-size, 12 !important);
}
.fs-24 {
  @include pxRem(font-size, 24 !important);
}
.lh-24 {
  @include pxRem(line-height, 24 !important);
}
.w-100 {
  width: 100%;
}
.label-status {
  .label {
    @include pxRem(margin-left, 4);
  }
  .status {
    @include pxRem(width, 8);
    @include pxRem(height, 8);
    border-radius: 50%;
  }
}
.label-status-active {
  .label {
    color: map-get($colors, txt-green);
  }
  .status {
    background-color: map-get($colors, txt-green);
  }
}
.label-status-disable {
  .label {
    color: map-get($colors, gray);
  }
  .status {
    background-color: map-get($colors, bg-gray-1);
  }
}
.label-type {
  @include pxRem(border-radius, 100);
  @include pxRem(padding, 4 8);
  @include pxRem(line-height, 34);
  color: map-get($colors, gray);
  border: 1px solid map-get($colors, border-gray-2);
}
.label-actions {
  flex-wrap: wrap;
}
.label-action {
  @include pxRem(line-height, 20);
  @include pxRem(font-size, 14);
  @include pxRem(padding, 3 7);
  font-weight: 500;
  white-space: nowrap;
  border: 1px solid map-get($colors, border-gray-3);
  &:not(:first-child) {
    @include pxRem(margin-top, 4);
  }
}
.label-purple {
  @include pxRem(border-radius, 4);
  color: map-get($colors, purple);
  background-color: map-get($colors, bg-purple);
}
.label-pink {
  @include pxRem(border-radius, 8);
  color: map-get($colors, pink);
  background-color: map-get($colors, bg-pink);
}
.label-inquire,
.label-report {
  @include pxRem(font-size, 12);
  @include pxRem(line-height, 16);
  @include pxRem(padding, 4 8);
  @include pxRem(border-radius, 8);
}
.label-inquire {
  color: map-get($colors, txt-blue);
  background-color: map-get($colors, bg-blue);
}
.label-report {
  color: map-get($colors, green);
  background-color: map-get($colors, bg-green);
}
.rotate-180 {
  transform: rotate(180deg);
}
.flex-1 {
  flex: 1;
}
.pointer {
  cursor: pointer;
}
.word-break {
  word-wrap: break-word;
  word-break: break-word;
}
.fs-18 {
  @include pxRem(font-size, 18 !important);
}
.lh-28 {
  @include pxRem(line-height, 28 !important);
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.pointer-events-none {
  pointer-events: none;
}
.min-height-no-footer {
  min-height: calc(100vh - #{convertPxToRem(60)});
}
.border-0 {
  border: 0 !important;
}
@include respond-below(medium) {
  .w-sp-100 {
    width: 100% !important;
  }
}
.w-name-header {
  // 72px is the width of user icon + setting icon + padding
  width: calc(100% - #{convertPxToRem(76)});
}
