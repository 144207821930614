.flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.flex-direction-column {
  flex-direction: column;
}
@include respond-below(medium) {
  .txt-sp-left {
    text-align: left !important;
  }
}
