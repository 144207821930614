.tab-list {
  .tab-item {
    @include pxRem(font-size, 14);
    @include pxRem(line-height, 20);
    @include pxRem(padding, 14 16);
    color: map-get($colors, txt-gray-1);
    font-weight: 700;
    &.Mui-selected {
      color: map-get($colors, txt-black-1);
    }
  }
}
button.MuiTab-root {
  text-transform: unset;
}
