.txt-left {
  text-align: left;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}
.txt-error {
  color: map-get($colors, txt-error);
}
.txt-note {
  color: map-get($colors, txt-note);
  font-style: italic;
}
.txt-blue {
  color: map-get($colors, blue);
}
.txt-gray {
  color: map-get($colors, gray);
}
.txt-gray-1 {
  color: map-get($colors, txt-gray-1) !important;
}
.txt-gray-2 {
  color: map-get($colors, txt-gray-2) !important;
}
.txt-gray-3 {
  color: map-get($colors, txt-gray-3);
}
.txt-black {
  color: map-get($colors, black) !important;
}
.txt-black-1 {
  color: map-get($colors, txt-black-1) !important;
}
.txt-red {
  color: map-get($colors, red) !important;
}
.txt-link {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: map-get($colors, blue);
  cursor: pointer;
}
.txt-ellipsis {
  display: block;
  margin: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txt-nowrap {
  white-space: nowrap;
}
.txt-pre-wrap {
  white-space: pre-wrap;
}
.txt-pre {
  white-space: pre;
}
.txt-lowercase {
  text-transform: lowercase;
}
.text-transform-none {
  text-transform: none !important;
}
